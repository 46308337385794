import { Button, Hidden, Link, Typography } from '@hermes/web-components'
import React from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import NextImage from 'next/image'
import { ArticleProps } from '../../types/article'
import { BlogCardContainer, BlogCardImageContainer, BlogCardInfoContainer } from './styles'

interface BlogCardProps extends ArticleProps {
  url: string
}

const BlogCard = ({ previewImage, title, description, url }: BlogCardProps) => {
  const router = useRouter()
  const onOpenArticle = async () => {
    await router.push(String(url), undefined, { shallow: true })
  }

  return (
    <>
      <BlogCardContainer className="blog-card-container">
        <BlogCardImageContainer
          onClick={onOpenArticle}
          sx={{
            cursor: 'pointer'
          }}
          className="blog-card-image-container"
        >
          <NextImage src={previewImage.data?.attributes.url || '/img/default-cover.png'} layout="fill" alt={title} />
        </BlogCardImageContainer>
        <BlogCardInfoContainer>
          <NextLink href={url} passHref>
            <Typography
              className="blog-card-info-title"
              component={Link}
              sx={{
                textDecoration: 'none',
                color: 'black'
              }}
            >
              {title}
            </Typography>
          </NextLink>

          <Typography
            onClick={onOpenArticle}
            sx={{ marginTop: '25px', cursor: 'pointer' }}
            className="blog-card-info-description"
          >
            {description}
          </Typography>
        </BlogCardInfoContainer>
      </BlogCardContainer>
      <Hidden mdUp implementation="css">
        <Button
          component="a"
          href={url}
          sx={{
            marginTop: '25px',
            width: '100%',
            padding: '17px 0'
          }}
          variant="outlined"
        >
          Details here
        </Button>
      </Hidden>
    </>
  )
}

export default BlogCard
