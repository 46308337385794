import { Box, CarouselSlide, Grid, styledComponent, Tabs } from '@hermes/web-components'
import Section from '../../Section'

export const ModelsSectionContainer = styledComponent(Section)(({ theme }) => ({
  background: theme.palette.additional.paperLinear,
  backgroundImage: 'url(/img/background-patterns/desktop/services-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'url(/img/background-patterns/mobile/services-section.svg)',
    backgroundPositionY: '88%'
  }
}))

export const ModelsTabWrapper = styledComponent(Box)({})

export const ModelsTabContent = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'tabIndex' && prop !== 'modelsLength'
})<{ tabIndex?: number; modelsLength?: number }>(({ tabIndex, modelsLength }) => ({
  maxHeight: '500px',
  overflow: 'hidden',
  display: 'grid',
  gap: '40px',
  transition: '0.2s',
  fontSize: '20px',
  lineHeight: '28px',
  position: 'relative',
  ...((tabIndex || 0) < (modelsLength || 0)
    ? {
        '&:before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '300px',
          zIndex: 1,
          background: 'linear-gradient(0deg, #fff 30%, rgba(255,0,0,0) 100%)'
        }
      }
    : {})
}))

export const ModelsTabs = styledComponent(Tabs)(({ theme }) => ({
  overflow: 'visible',
  '.MuiTabs-scroller': {
    overflow: 'visible !important'
  },
  '.MuiButtonBase-root': {
    overflowX: 'visible',
    position: 'relative',
    color: theme.palette.typography.primary,
    zIndex: 2,
    fontSize: '20px',
    lineHeight: '26.133px',
    alignItems: 'start',
    padding: '0',
    width: '382px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      textAlign: 'start'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '14px 23px'
    }
  },
  '.MuiButtonBase-root.Mui-selected': {
    background: 'linear-gradient(90deg, #3F95C3 -8.31%, #728EDD 54.21%, #36529F 112.94%)',
    backgroundClip: 'text',
    webkitBackgroundClip: 'text',
    color: 'transparent',
    webkitTextFillColor: 'transparent'
  },
  '.MuiTabs-indicator': {
    zIndex: 1,
    width: 'calc(100% + 35px)',
    borderRadius: '25px',
    border: 'double 2px transparent',
    left: '-25px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(90deg, #3F95C3 -8.31%, #728EDD 54.21%, #36529F 112.94%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

export const ModelsSlide = styledComponent(CarouselSlide)({
  opacity: 0.15,
  transition: '0.2s',
  '.MuiTypography-root': {
    display: '-webkit-box',
    maxHeight: '100px',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden'
  },
  '&.is-active': {
    opacity: 1,
    '.MuiTypography-root': {
      WebkitLineClamp: 'unset',
      maxHeight: 'unset'
    }
  }
})

export const TechStackCarouselsWrapper = styledComponent(Grid)({
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '300px',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(180deg, #fff 10%, rgba(255,0,0,0) 50%)'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '300px',
    zIndex: 1,
    pointerEvents: 'none',
    background: 'linear-gradient(0deg, #fff 10%, rgba(255,0,0,0) 50%)'
  }
})
