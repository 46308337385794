import { styledComponent } from '@hermes/web-components'
import { animated } from '@react-spring/web'

export const WeProvideCardWrapper = styledComponent(animated.div)(({ theme }) => ({
  borderRadius: '20px',
  flex: '0 0 calc(33.333% - 20px)',
  border: '3px solid transparent',
  '.we-provide-card-number': {
    color: theme.palette.background.default,
    WebkitTextStrokeWidth: '2px'
  },
  [theme.breakpoints.up('sm')]: {
    '&:hover': {
      border: 'solid 3px transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(90deg, #3F95C3 -8.31%, #728EDD 54.21%, #36529F 112.94%)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box'
    }
  }
}))
