import { Box, Grid, Typography } from '@hermes/web-components'
import React from 'react'
import { useInView } from '@react-spring/web'
import { WeProvideCardWrapper } from './styles'
import getColorByIndex from '../../../../utils/getColorByIndex'

export interface WeProvideCardProps {
  title: string
  subtitle: string
  index: number
}

const WeProvideCard = ({ title, subtitle, index }: WeProvideCardProps) => {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        transform: 'translateY(50px)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)'
      }
    }),
    { rootMargin: '-50px 0px' }
  )
  const indexColor = getColorByIndex(index)

  return (
    <WeProvideCardWrapper ref={ref} style={springs}>
      <Grid container wrap="nowrap" gap="15px" p="20px">
        <Typography
          className="we-provide-card-number"
          sx={{
            WebkitTextStrokeColor: indexColor
          }}
          fontSize="70px"
          fontWeight={800}
          lineHeight="60px"
        >
          {index + 1}
        </Typography>
        <Box>
          <Typography fontSize="20px" mb="10px">
            {title}
          </Typography>
          <Typography fontSize="14px">{subtitle}</Typography>
        </Box>
      </Grid>
      {/* <Hidden mdUp implementation="css"> */}
      {/*  <Button */}
      {/*    sx={{ */}
      {/*      width: 'calc(100% - 20px)', */}
      {/*      margin: '10px', */}
      {/*      padding: '17px 0' */}
      {/*    }} */}
      {/*    variant="outlined" */}
      {/*  > */}
      {/*    Details here */}
      {/*  </Button> */}
      {/* </Hidden> */}
    </WeProvideCardWrapper>
  )
}
export default WeProvideCard
