import { Typography } from '@hermes/web-components'
import { StrapiMedia, StrapiResponse } from '../../../../types/strapi'
import { TechCardContainer } from './styles'
import StrapiImage from '../../../StrapiImage'

export interface TechCardProps {
  title: string
  type: string
  techLogo: StrapiResponse<StrapiMedia>
}

const TechCard = ({ techLogo, type, title }: TechCardProps) => (
  <TechCardContainer container direction="column" wrap="nowrap" gap="14px" justifyContent="center">
    <StrapiImage
      image={techLogo}
      lazyOffset={100}
      lazy="carousel"
      sx={{
        maxWidth: '60px',
        maxHeight: '60px',
        height: 'auto',
        objectFit: 'contain'
      }}
    />
    <Typography textTransform="uppercase" variant="caption" lineHeight="28px">
      <b>{title}</b> <br /> {type}
    </Typography>
  </TechCardContainer>
)

export default TechCard
