import { Box, CarouselSlide, styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const BlogSectionContainer = styledComponent(Section)(({ theme }) => ({
  backgroundImage: 'url(/img/background-patterns/desktop/blog-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'url(/img/background-patterns/mobile/blog-section.svg)',
    backgroundSize: '100%'
  }
}))

export const BlogSlideWrapper = styledComponent(Box)({
  overflow: 'hidden'
})

export const BlogCarousel = styledComponent(Box)(() => ({}))

export const StyledCarouselSlide = styledComponent(CarouselSlide)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '23% !important',
    '&:not(.is-active)': {
      '.blog-card-container': {
        flexDirection: 'column-reverse',
        '.blog-card-image-container': {
          aspectRatio: '1/1',
          minWidth: '100% !important',
          height: 'auto !important'
        },
        '.blog-card-info-title': {
          WebkitLineClamp: '2'
        },
        '.blog-card-info-description': {
          display: 'none'
        }
      }
    },
    '&.is-active': {
      width: 'calc(77% - 50px) !important'
    }
  }
}))
