import React, { useRef } from 'react'
import { Carousel, CarouselSlide, Container, useTheme, useMediaQuery } from '@hermes/web-components'
import WeProvideCard, { WeProvideCardProps } from './Card'
import SlideHeader from '../../SlideHeader'
import { WeProvideContainer, WeProvideWrapper } from './styles'

interface WeProvideSectionProps {
  title: string
  list: WeProvideCardProps[]
}

const WeProvideSection = ({ title, list }: WeProvideSectionProps) => {
  const sectionRef = useRef<HTMLElement>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <WeProvideContainer id="we-provide" ref={sectionRef}>
      <SlideHeader headingTitle={title} />
      <Container maxWidth="lg">
        {isMobile ? (
          <Carousel slidesPerView={1} navigation>
            {list.map((card, index) => (
              <CarouselSlide key={`we-provide-card-mobile-${index}`}>
                <WeProvideCard {...card} index={index} />
              </CarouselSlide>
            ))}
          </Carousel>
        ) : (
          <WeProvideWrapper container wrap="wrap" gap="20px" justifyContent="center">
            {list.map((card, index) => (
              <WeProvideCard key={`we-provide-card-${index}`} {...card} index={index} />
            ))}
          </WeProvideWrapper>
        )}
      </Container>
    </WeProvideContainer>
  )
}

export default WeProvideSection
