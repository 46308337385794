import { Box, styledComponent } from '@hermes/web-components'

export const BlogCardContainer = styledComponent(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: '2px',
  boxSizing: 'border-box',
  border: `0.2px solid ${theme.palette.additional.border}`,
  padding: '30px',
  height: 'calc(100% - 4px)',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  transition: '0.3s',
  '&:hover': {
    border: `0.2px solid ${theme.palette.primary.lightest}`,
    boxShadow: `0px 0px 0px 1.8px ${theme.palette.primary.lightest}`,
    '.blog-card-image-container': {
      img: {
        transform: 'scale(1.2)'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const BlogCardImageContainer = styledComponent(Box)(() => ({
  position: 'relative',
  minWidth: '265px',
  height: '265px',
  overflow: 'hidden',
  borderRadius: '14px',
  img: {
    transition: '0.2s',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  }
}))

export const BlogCardInfoContainer = styledComponent(Box)({
  display: 'flex',
  flexDirection: 'column',
  '.blog-card-info-title': {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  '.blog-card-info-description': {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    fontSize: '16px'
  }
})
