import {
  Carousel,
  CarouselSlide,
  Container,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { SyntheticEvent, useState } from 'react'
import SlideHeader from '../../SlideHeader'
import {
  ModelsSectionContainer,
  ModelsSlide,
  ModelsTabContent,
  ModelsTabs,
  ModelsTabWrapper,
  TechStackCarouselsWrapper
} from './styles'
import ModelCard, { ModelCardProps } from './Card'
import TechCard, { TechCardProps } from './TechCard'

interface ModelsSectionProps {
  title: string
  models: ModelCardProps[]
  techStackTitle: string
  techStack: TechCardProps[]
}

function divideTechStackArray(array: TechCardProps[]): TechCardProps[][] {
  if (array?.length === 0 || !array) {
    return []
  }

  const partSize = Math.ceil(array.length / 3)

  const part1 = array.slice(0, partSize)
  const part2 = array.slice(partSize, 2 * partSize)
  const part3 = array.slice(2 * partSize)

  return [part1, part2, part3]
}

const TechStackModel = ({ techCards }: { techCards: TechCardProps[] }) => {
  const techStackParts = divideTechStackArray(techCards)

  return (
    <TechStackCarouselsWrapper container gap="15px" wrap="nowrap" height="500px">
      {techStackParts.map((part, index) => (
        <Carousel
          slidesPerView={3}
          updateOnMove
          spaceBetween={10}
          reverseAutoScroll={index % 2 === 0}
          loop
          autoScroll
          speed="slower"
          vertical
          lazyLoad
          verticalHeight={580}
          key={`tech-carousel-${index}`}
        >
          {part.map((techCard, cardIndex) => (
            <CarouselSlide key={`tech-card-${index}-${cardIndex}`}>
              <TechCard {...techCard} key={`tech-card-${index}-${cardIndex}`} />
            </CarouselSlide>
          ))}
        </Carousel>
      ))}
    </TechStackCarouselsWrapper>
  )
}

const ModelsSection = ({ title, models, techStackTitle, techStack }: ModelsSectionProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangeTab = (e: SyntheticEvent, value: number) => {
    setTabIndex(value)
  }

  const techStackModel = {
    title: techStackTitle,
    description: <TechStackModel techCards={techStack} />,
    id: models.length
  }

  const modelsWithTechStack = techStack?.length ? [...models, techStackModel] : models

  return (
    <ModelsSectionContainer id="models">
      <SlideHeader headingTitle={title} />
      <Container>
        {isMobile ? (
          <Carousel slidesPerView={1} spaceBetween={45} navigation>
            {models.map((model, index) => (
              <CarouselSlide key={`model-card-${index}`}>
                <ModelCard
                  sx={{
                    height: '100%'
                  }}
                  {...model}
                />
              </CarouselSlide>
            ))}
          </Carousel>
        ) : (
          <ModelsTabWrapper>
            <Grid container direction="row" wrap="nowrap" gap="100px">
              <ModelsTabs orientation="vertical" value={tabIndex} onChange={handleChangeTab}>
                {modelsWithTechStack?.map((model, index) => (
                  <Tab key={`models-tab-${index}`} label={model.title} value={index} disableRipple={true} />
                ))}
              </ModelsTabs>
              <ModelsTabContent tabIndex={tabIndex} modelsLength={models?.length}>
                <Carousel
                  slidesPerView={1}
                  spaceBetween={45}
                  vertical
                  disableDrag
                  currentSlide={tabIndex > models.length ? models.length - 1 : tabIndex}
                  updateOnMove
                >
                  {modelsWithTechStack.map((model, index) => (
                    <ModelsSlide key={`model-card-${index}`}>
                      {typeof model.description === 'string' ? (
                        <Typography variant="h4" component={'p'} key={`model-${index}`}>
                          {model.description}
                        </Typography>
                      ) : (
                        model.description
                      )}
                    </ModelsSlide>
                  ))}
                </Carousel>
              </ModelsTabContent>
            </Grid>
          </ModelsTabWrapper>
        )}
      </Container>
    </ModelsSectionContainer>
  )
}

export default ModelsSection
