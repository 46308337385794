import { Grid, styledComponent } from '@hermes/web-components'

export const TechCardContainer = styledComponent(Grid)(({ theme }) => ({
  width: '180px',
  height: '180px',
  border: `0.2px solid ${theme.palette.additional.border}`,
  borderRadius: '14px',
  padding: '20px',
  transition: '0.2s'
}))
