import { Carousel, Container, useMediaQuery, useTheme } from '@hermes/web-components'
import { BlogCarousel, BlogSectionContainer, BlogSlideWrapper, StyledCarouselSlide } from './styles'
import SlideHeader from '../../SlideHeader'
import { StrapiAttributes, StrapiResponse } from '../../../types/strapi'
import { ArticleProps } from '../../../types/article'
import BlogCard from '../../BlogCard'

interface BlogSectionProps {
  title: string
  blog_articles: StrapiResponse<StrapiAttributes<ArticleProps>[]>
}

const BlogSection = ({ title, blog_articles }: BlogSectionProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <BlogSectionContainer id="blog">
      <SlideHeader
        headingTitle={title}
        href={'/insights'}
        sx={{
          position: 'relative',
          zIndex: 1,
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        }}
      />
      <BlogSlideWrapper>
        <Container maxWidth="lg">
          <BlogCarousel>
            <Carousel
              updateOnMove
              navigation={blog_articles.data.length > 2}
              spaceBetween={45}
              slidesPerGroup={1}
              slidesPerView={isMobile ? 1 : 2}
            >
              {blog_articles.data.map(({ attributes }, index) => (
                <StyledCarouselSlide key={`carousel-blog-slide-${index}`}>
                  <BlogCard url={`/insights/${attributes.slug}`} {...attributes} />
                </StyledCarouselSlide>
              ))}
            </Carousel>
          </BlogCarousel>
        </Container>
      </BlogSlideWrapper>
    </BlogSectionContainer>
  )
}

export default BlogSection
