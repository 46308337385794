import { Box, Grid, Image, Link, Typography } from '@hermes/web-components'
import React, { ReactNode } from 'react'
import { useInView } from '@react-spring/web'
import { SxProps } from '@mui/system'
import NextLink from 'next/link'
import { ModelCardWrapper } from './styles'
import { StrapiMedia, StrapiResponse } from '../../../../types/strapi'

export interface ModelCardProps {
  title: string
  description: string | ReactNode
  slug: string
  titleIcon?: StrapiResponse<StrapiMedia>
  titleColor?: string
  sx?: SxProps
  id: number
}

const ModelCard = ({ title, description, titleIcon, titleColor, sx, slug }: ModelCardProps) => {
  const serviceHref = `/services/${slug}`
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(50px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }))

  return (
    <ModelCardWrapper sx={sx} ref={ref} style={springs}>
      <Grid container p="10px 20px" gap="10px" alignItems="center" position="relative" wrap="nowrap">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            backgroundColor: titleColor,
            opacity: 0.1
          }}
        />
        {titleIcon?.data?.attributes && (
          <Image
            lazy
            lazyOffset={100}
            sx={{
              display: 'grid',
              position: 'relative',
              zIndex: 2
            }}
            width="30px"
            height="30px"
            alt={title}
            src={titleIcon.data.attributes.url}
          />
        )}
        <NextLink href={serviceHref} passHref>
          <Typography
            component={Link}
            sx={{
              position: 'relative',
              zIndex: 2,
              color: 'typography.primary',
              textDecoration: 'none'
            }}
            fontWeight={700}
            mb={0}
          >
            {title}
          </Typography>
        </NextLink>
      </Grid>
      <NextLink href={serviceHref} passHref>
        <Grid
          component={Link}
          container
          height="100%"
          sx={{
            color: 'typography.primary',
            textDecoration: 'none'
          }}
        >
          <Typography p="20px">{description}</Typography>
        </Grid>
      </NextLink>
    </ModelCardWrapper>
  )
}
export default ModelCard
