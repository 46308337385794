import { Grid, styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const WeProvideContainer = styledComponent(Section)(({ theme }) => ({
  backgroundImage: 'url(/img/background-patterns/desktop/we-provide-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'url(/img/background-patterns/mobile/we-provide-section.svg)',
    backgroundSize: '100%',
    backgroundPositionY: '50px'
  }
}))

export const WeProvideWrapper = styledComponent(Grid)({})
